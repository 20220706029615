.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	margin-left: -8px;
	position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	box-sizing: content-box;
	position: absolute;
	border: 8px solid transparent;
	height: 0;
	width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	content: "";
	z-index: -1;
	border-width: 8px;
	left: -8px;
	border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
	top: 0;
	margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::before {
	border-top: none;
	border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::before {
	top: -1px;
	border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	bottom: 0;
	margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	border-bottom: none;
	border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	bottom: -1px;
	border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
	display: inline-block;
	padding: 0;
	border: 0;
	width:100%;
}

.react-datepicker {
	font-size: 1.2rem;
	background-color: #fff;
	color: #000;
	border: 1px solid #aeaeae;
	border-radius: 0.3rem;
	display: inline-block;
	position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
	left: 35px;
}

.react-datepicker__triangle {
	position: absolute;
	left: 50px;
}

.react-datepicker-popper {
	z-index: 8;
}

.react-datepicker-popper[data-placement="bottom-end"]
	.react-datepicker__triangle,
.react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
	left: auto;
	right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
	margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
	margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
	left: auto;
	right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
	margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
	left: 42px;
	right: auto;
}

.react-datepicker__header {
	text-align: center;
	background-color: #f0f0f0;
	border-bottom: 1px solid #aeaeae;
	border-top-left-radius: 0.3rem;
	padding-top: 8px;
	position: relative;
}

.react-datepicker__header--time {
	padding-bottom: 8px;
	padding-left: 5px;
	padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
	border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
	border-top-right-radius: 0.3rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	margin-top: 0;
	color: #000;
	font-weight: bold;
	font-size: 1.51rem;
}

.react-datepicker-time__header {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.react-datepicker__navigation {
	background: none;
	line-height: 20px;
	text-align: center;
	cursor: pointer;
	position: absolute;
	top: 13px;
	width: 0;
	padding: 0;
	border: 0.45rem solid transparent;
	z-index: 1;
	height: 10px;
	width: 10px;
	text-indent: -999em;
	overflow: hidden;
}

.react-datepicker__navigation--previous {
	left: 10px;
	border-right-color: $deep-green;

	&:hover {
		opacity: 0.9;
	}
}

.react-datepicker__navigation--previous
	.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
	border-right-color: #e6e6e6;
	cursor: default;
}

.react-datepicker__navigation--next {
	right: 10px;
	border-left-color: $deep-green;

	&:hover {
		opacity: 0.9;
	}
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
	right: 95px;
}

.react-datepicker__navigation--next
	.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
	border-left-color: #e6e6e6;
	cursor: default;
}

.account .react-datepicker__month-container {
	float: left;
	&:after {
		content: "Delivery dates will change for all subsequent deliveries of this plan";
		position: relative;
		display: block;
		max-width: 225px;
		text-align: center;
		width: 100%;
		line-height: 1.4;
		padding-top: 5px;
		padding-bottom: 5px;
		font-size: 1.2rem;
	}
}

.react-datepicker__day-names,
.react-datepicker__week {
	white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	color: #000;
	display: inline-block;
	width: 30px;
	line-height: 30px;
	text-align: center;
	font-weight: bold;
	margin: 0.166rem;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
	cursor: default;
	background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
	cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
	border-radius: 0.3rem;
	background-color: $off-white;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
	font-weight: bold;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	border-radius: 0.3rem;
	background-color: $deep-green;
	color: $white;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
	background-color: $color-secondary;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__month--selecting-range
	.react-datepicker__month-text--in-range,
.react-datepicker__month--selecting-range
	.react-datepicker__quarter-text--in-range,
.react-datepicker__month--selecting-range
	.react-datepicker__year-text--in-range {
	background-color: #f0f0f0;
	color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
	cursor: default;
	color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
	background-color: transparent;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
	background-color: #f0f0f0;
}

.react-datepicker__input-container {
	position: relative;
	display: inline-block;
	width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
	border: 1px solid transparent;
	border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
	cursor: pointer;
}

.react-datepicker__year-read-view:hover
	.react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover
	.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover
	.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover
	.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover
	.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover
	.react-datepicker__month-read-view--down-arrow {
	border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	border-top-color: #ccc;
	float: right;
	margin-left: 20px;
	top: 8px;
	position: relative;
	border-width: 0.45rem;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
	line-height: 20px;
	width: 100%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-bottom-left-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
	background-color: #ccc;
}

.react-datepicker__year-option:hover
	.react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover
	.react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover
	.react-datepicker__navigation--years-upcoming {
	border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover
	.react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover
	.react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover
	.react-datepicker__navigation--years-previous {
	border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
	position: absolute;
	left: 15px;
}

.react-datepicker__close-icon {
	cursor: pointer;
	background-color: transparent;
	border: 0;
	outline: 0;
	padding: 0px 6px 0px 0px;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}

.react-datepicker__close-icon::after {
	cursor: pointer;
	background-color: $color-secondary;
	color: #fff;
	border-radius: 50%;
	height: 16px;
	width: 16px;
	padding: 2px;
	font-size: 1.2rem;
	line-height: 1;
	text-align: center;
	display: table-cell;
	vertical-align: middle;
	content: "\00d7";
}

.react-datepicker__today-button {
	background: #f0f0f0;
	border-top: 1px solid #aeaeae;
	cursor: pointer;
	text-align: center;
	font-weight: bold;
	padding: 5px 0;
	clear: left;
}

.react-datepicker__input-container input {
	width:100%;
	text-align:center;
}