@charset 'UTF-8';
////////////////////////////////////////
// Utilities
////////////////////////////////////////
@import "utils/variables";
@import "utils/mixins";
@import "base/layout";
@import "base/helpers";
@import "base/reset";
@import "base/typography";
@import "base/datePicker";

body {
	background: $off-white;
	font-family: $base-font-family;
}
